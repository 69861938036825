<template>
  <div class="stores__container">
    <h1 class="pb-2">{{ title }}</h1>

    <b-row
      v-if="userData.role_name !== 'establishment_presale_clerk'"
      class="mt-2 match-height"
    >
      <b-col class="stores__grid">
        <b-card
          v-if="
            (userData.role_name === 'admin' ||
              userData.role_name === 'establishment_admin') &&
            !tab
          "
          class="store__card"
        >
          <div
            class="
              text-center
              justify-content-center
              d-flex
              flex-column
              align-items-center
            "
            @click="
              $router.push({
                name: 'new-store',
                params: { id: $route.params.id },
              })
            "
          >
            <div class="d-flex justify-content-center mb-1 add__icon mt--5">
              <feather-icon icon="PlusIcon" size="24" />
            </div>
            <div class="establishment-name">Agregar</div>
            <div class="establishment-name">Tienda</div>
          </div>
        </b-card>
        <b-card
          v-for="store in stores"
          :key="store.id"
          class="store__card"
          @click="handleShowStoreModal(store)"
        >
          <div class="mt-1 text-center">
            <div class="d-flex justify-content-center mt--5">
              <span @click="handleLogoRedirect(store.id)">
                <b-avatar
                  :src="`${store.logo}`"
                  size="60"
                  class="cursor-pointer"
                />
              </span>
            </div>

            <!-- Dropdown -->
            <div class="dropdown settings-btn">
              <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'walleat-add-store-credit',
                    params: { store_id: store.id },
                  }"
                >
                  Añadir saldo a la tienda
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{ name: 'store-users', params: { id: store.id } }"
                >
                  Gestionar usuarios
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'store-suppliers',
                    params: {
                      establishment_id: $route.params.id,
                      store_id: store.id,
                    },
                  }"
                >
                  Gestionar proveedores
                </b-dropdown-item>
                <b-dropdown-item @click.stop="handleShareStoreModal(store.id)">
                  Compartir tienda
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item
                  :to="{
                    name: 'edit-store',
                    params: { id: $route.params.id, store_id: store.id },
                  }"
                >
                  Editar tienda
                </b-dropdown-item>
                <b-dropdown-item
                  :to="{
                    name: 'edit-store-csd',
                    params: { id: $route.params.id, store_id: store.id },
                  }"
                >
                  Gestionar CSD
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <h5 class="store-name">
              {{ store.name }}
            </h5>
            <div class="d-flex align-items-center justify-content-center mb-1">
              <b-badge variant="light-secondary">
                <feather-icon icon="BoxIcon" class="mr-1" />
                {{ store.store_products_count }}
              </b-badge>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!-- List of stores for users Establishment Presale Clerk-->
    <b-row
      v-if="userData.role_name === 'establishment_presale_clerk'"
      class="mt-3 match-height"
    >
      <b-col
        v-for="store in filteredStores"
        :key="store.id"
        cols="12"
        sm="6"
        md="6"
        lg="6"
        xl="4"
      >
        <b-card v-if="store.category !== 'physical'" class="mt-1 text-center">
          <div
            class="d-flex justify-content-center mt--5 mb-1 position-relative"
          >
            <span @click="handleLogoRedirect(store.id)">
              <b-avatar
                :src="`${store.logo}`"
                size="85"
                class="cursor-pointer"
              />
            </span>
          </div>

          <h5 class="store-name">
            {{ store.name }}
          </h5>
          <div class="d-flex align-items-center justify-content-center">
            <b-badge variant="light-secondary">
              <feather-icon icon="BoxIcon" class="mr-1" />
              {{ store.store_products_count }}
            </b-badge>
          </div>

          <b-button
            v-if="
              store.store_products_count > 0 &&
              userData.role_name === 'establishment_presale_clerk'
            "
            block
            class="custom-btn custom-btn-color"
            :to="{ name: 'e-commerce-presale', params: { store_id: store.id } }"
          >
            <i class="fas fa-truck"></i>
            Realizar Preventa
          </b-button>

          <b-button
            v-if="store.store_products_count > 0"
            block
            class="custom-btn custom-btn-color"
            :to="{ name: 'online-sales-view', params: { id: store.id } }"
          >
            <i class="fas fa-list"></i>
            Preventas realizadas
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="shareStoreModal" hide-footer>
      <b-card no-body>
        <div
          class="
            m-2
            justify-content-center
            d-flex
            flex-column
            align-items-center
            text-center
          "
        >
          <h3>Compartir tienda</h3>
          <qriously :value="qrcodeValue" :size="200" />
          <b-button
            variant="success"
            @click="shareOnSocialMedia"
            v-if="webShare"
          >
            Compartir por redes sociales
          </b-button>
          <div v-if="!webShare" class="w-100">
            <b-form-group label="Correo electrónico:" label-for="emailToShare">
              <b-form-input
                id="emailToShare"
                v-model="emailToShare"
                required
                class="my-1 w-100 mx-auto"
              />
            </b-form-group>
            <a :href="generatedMail"> Envia por correo </a>
          </div>
        </div>
      </b-card>
    </b-modal>

    <store-modal :store="selectedStore" :establishment="establishment" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import VueQriously from "vue-qriously"

import StoreModal from "./StoreModal.vue"

export default {
  directives: {
    VueQriously,
  },
  components: {
    StoreModal,
  },
  data() {
    return {
      establishment: {},
      stores: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      qrcodeValue: "",
      webShare: navigator.canShare,
      emailToShare: "",
      selectedStore: {},
      tab: this.$route.query.tab,
    }
  },

  mounted() {
    Promise.all([
      this.fetchShortEstablishment(this.$route.params.id),
      this.fetchShortStores({
        by_establishment: this.$route.params.id,
        by_store_product_count: this.tab ? true : undefined,
        meta: {
          pagination: {
            page: 1,
            per_page: 100000,
          },
        },
      }),
    ]).then(([establishmentResponse, storesResponse]) => {
      this.establishment = establishmentResponse
      this.stores = storesResponse.data
    })
  },
  computed: {
    ...mapGetters("establishments", ["currentEstablishment"]),
    filteredStores() {
      return this.establishment.stores_attributes.filter(
        (store) => store.category !== "physical"
      )
    },

    title(){
      switch (this.tab) {
        case "reporte":
          return "Selecciona una tienda para ver sus reportes"
        case "ventas":
          return "Selecciona una tienda para ver sus ventas"
        case "inventario":
          return "Selecciona una tienda para administrar su inventario"
        case "comprar-inventario":
          return "Selecciona una tienda para comprar su inventario"
        default:
          return "Selecciona una tienda"
      }
    },

    generatedMail(event) {
      return `mailto:${this.emailToShare}?subject=Ve los productos de esta tienda&body=Ingresa a este link para ver los productos de la tienda: ${this.qrcodeValue}`
    },
  },
  methods: {
    ...mapActions("establishments", ["fetchShortEstablishment"]),
    ...mapActions("stores", ["fetchReferralToken", "fetchShortStores"]),
    handleLogoRedirect(storeId) {
      // if(this.establishment.establishment_type !== 'corporate_education') {
      //   this.$router.push({ name: 'POS', params: { store_id: storeId } })
      // }
    },
    handleShareStoreModal(storeId) {
      this.$bvModal.show("shareStoreModal")
      this.fetchReferralToken({ storeId: storeId }).then((response) => {
        const encodedToken = encodeURIComponent(response.token)
        const establishmentSlug = this.currentEstablishment.slug

        this.qrcodeValue = `${process.env.VUE_APP_BASE_URL}/${establishmentSlug}?token=${encodedToken}&store_id=${storeId}`
        console.log(this.qrcodeValue)
      })
    },
    handleShowStoreModal(store) {
      if (this.tab) {
        switch (this.tab) {
          case "reporte":
            this.$router.push({
              name: "sales-view",
              params: { id: store.id },
            })
            break
          case "ventas":
            this.$router.push({
              name: "online-sales-view",
              params: { id: store.id },
            })
            break
          case "inventario":
            this.$router.push({
              name: "inventory_options",
              params: { id: store.id },
            })
            break
          case "comprar-inventario":
            this.$router.push({
              name: "establishment-landing-page",
              params: { slug: "marketplace" },
              query: { from_store_id: store.id },
            })
            break
          default:
            this.$bvModal.show("storeModal")
            this.selectedStore = store
        }
      } else {
        this.$bvModal.show("storeModal")
        this.selectedStore = store
      }
    },
    async shareOnSocialMedia() {
      if (!navigator.canShare) {
        console.warn(
          "La API de Web Share no está disponible en este navegador."
        )
        return
      }

      const shareData = {
        title: "Walleat",
        text: "Anéxate a esta tienda",
        url: this.qrcodeValue,
        //files: [file],
      }

      try {
        await navigator.share(shareData)
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mt--5 {
  margin-top: -3rem !important;
}

.establishment-name {
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.2rem;
  font-weight: 600;
}

.settings-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.stores__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  grid-row: 1fr;

  @media (min-width: 350px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 640px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1536px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(7, 1fr);
  }

  @media (min-width: 2000px) {
    grid-template-columns: repeat(8, 1fr);
  }

  .store__card {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;

    // &:hover {
    //   transform: translateY(-5px);
    // }
  }
}

.stores__container {
  @media (min-width: 2044px) {
    max-width: 1444px;
    margin: 0 auto;
  }
}

.add__icon {
  background-color: rgb(89, 214, 89);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  color: white;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
